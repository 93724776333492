* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  /* border: 1px solid red; */
}
:root {
  --theme-color: #99262a;
}

.body {
  background: url("BgWallpaper.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container {
  background: rgba(255, 255, 255, 0.85);
  width: 1000px;
  height: 500px;
  padding: 3em 5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}
.box1 {
  width: 300px;
  height: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
}
.box2 {
  width: 400px;
  height: 80%;
  padding: 20px;
  display: flex;
  align-items: center;
  /* border:1px solid red; */
}
.box1 .logo,
.box2 .logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}
.box1 .logo img {
  width: 100%;
}
/* .box2 .logo img { */
.box2 .logo h5 {
  width: 100%;
  font-size: 1.4rem;
  text-align: center;
}
.box2 .logo .logoName {
  width: 60%;
  font-weight: 800;
  font-size: 2em;
}
.box2 .logo .logoName span {
  font-size: 0.5em;
  font-weight: 600;
  letter-spacing: 0.5em;
}
.box2 {
  flex-direction: column;
  justify-content: space-evenly;
}
.box2 .formData {
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.box2 .formData .email,
.box2 .formData .password,
.box2 .formData .contact,
.box2 .formData .dropdown,
.box2 .formData .button {
  width: 300px;
  border-radius: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.box2 .formData .dropdown {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.box2 .formData .dropdown select {
  height: 100%;
  width: 40%;
  border: 1px solid grey;
  border-radius: 10px;
  padding: 5px;
}
.box2 .formData .dropdown select:focus {
  outline: none;
}
.box2 .formData .email,
.box2 .formData .password,
.box2 .formData .contact {
  background: rgba(0, 0, 0, 0.1);
}
.box2 .formData .email:hover i,
.box2 .formData .password:hover i,
.box2 .formData .contact:hover i {
  color: var(--theme-color);
}

.box2 .formData .email i,
.box2 .formData .contact i,
.box2 .formData .password i {
  padding: 10px;
  width: 15%;
  padding-left: 20px;
  border: none;
}
.box2 .formData .email input,
.box2 .formData .contact input,
.box2 .formData .password input {
  padding: 10px;
  width: 85%;
  background: transparent;
  border: none;
  font-size: 0.75em;
  font-weight: 600;
}
.box2 .formData .email input:focus,
.box2 .formData .contact input:focus,
.box2 .formData .password input:focus {
  outline: none;
}
.box2 .formData .button {
  background: var(--theme-color);
  color: white;
  font-size: 1em;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .container .box1 {
    display: none;
  }
  .container {
    background: rgba(255, 255, 255, 0.85);
    width: 300px;
    padding:0px;
  }
  .box2 {
    width: 100%;
    height: 80%;
    display: flex;
    align-items: center;
    /* border:1px solid red; */
  }
  .box2 .logo h5 {
    font-size: 20px;
    text-align: center;
  }
  .box2 .formData {
    width: 100%;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .box2 .formData .email,
  .box2 .formData .password,
  .box2 .formData .contact,
  .box2 .formData .dropdown,
  .box2 .formData .button {
    width: 100%;
    border-radius: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .box2 .formData .email i,
  .box2 .formData .contact i,
  .box2 .formData .password i {
    padding: 7px;
    width: 10%;
    padding-left: 5px;
    border: none;
  }
  .box2 .formData .email input,
  .box2 .formData .contact input,
  .box2 .formData .password input {
    padding: 10px;
    width: 75%;
    background: transparent;
    border: none;
    font-size: 0.75em;
    font-weight: 600;
  }
}
